import PropTypes from 'prop-types';

export const routerParametersDefinition = PropTypes.shape({
  appspace: PropTypes.string.isRequired,
});

export const appSpaceDefinition = PropTypes.shape({
  app_id: PropTypes.string,
  app_space: PropTypes.string,
  display_name: PropTypes.string,
  role: PropTypes.string,
  grant: PropTypes.bool,
  user_id: PropTypes.string,
  org_profile: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    sport_sector: PropTypes.string,
    sport: PropTypes.string,
    type: PropTypes.string,
    organization_id: PropTypes.string,
    organization_name: PropTypes.string,
  }),
});

export const appDefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  appSpaceUrlPattern: PropTypes.string,
  logo: PropTypes.string,
  appSpaceIds: PropTypes.arrayOf(PropTypes.string),
});

export const authUserDefinition = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
});
